var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"align-center":"","justify-center":""}},[_c('div',{staticClass:"text-center"},[_c('v-overlay',{attrs:{"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1),_c('v-flex',{attrs:{"xs12":"","sm8":"","md4":"","lg3":"","xl2":""}},[(!_vm.showMessage)?_c('v-card',{staticClass:"pa-6"},[_c('v-container',[_c('v-layout',{attrs:{"align-center":"","justify-center":"","column":""}},[_c('v-flex',{staticClass:"text-center primary--text"},[(_vm.getStatusDarkMode)?_c('v-img',{attrs:{"src":require("@/assets/logo-inverted.png"),"max-width":"220"}}):_c('v-img',{attrs:{"src":require("@/assets/logo.png"),"max-width":"220"}})],1)],1)],1),_c('v-card-title',{staticClass:"justify-center"},[_vm._v(" Sign up for free ")]),_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('v-card-text',[_c('ValidationProvider',{ref:"providerName",attrs:{"name":"Priority","vid":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-icon":"person","label":"Name","type":"text","error-messages":errors,"data-test":"name-text"},model:{value:(_vm.newUser.name),callback:function ($$v) {_vm.$set(_vm.newUser, "name", $$v)},expression:"newUser.name"}})]}}],null,true)}),_c('ValidationProvider',{ref:"providerUsername",attrs:{"name":"Priority","vid":"username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-icon":"person","label":"Username","type":"text","error-messages":errors,"data-test":"username-text"},model:{value:(_vm.newUser.username),callback:function ($$v) {_vm.$set(_vm.newUser, "username", $$v)},expression:"newUser.username"}})]}}],null,true)}),_c('ValidationProvider',{ref:"providerEmail",attrs:{"name":"Priority","vid":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-icon":"email","label":"Email","type":"text","error-messages":errors,"data-test":"email-text"},model:{value:(_vm.newUser.email),callback:function ($$v) {_vm.$set(_vm.newUser, "email", $$v)},expression:"newUser.email"}})]}}],null,true)}),_c('ValidationProvider',{ref:"providerPassword",attrs:{"name":"Priority","rules":"required|password|comparePasswords:@currentPassword","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"id":"password","prepend-icon":"lock","label":"Password","type":"password","error-messages":errors,"data-test":"password-text"},model:{value:(_vm.newUser.password),callback:function ($$v) {_vm.$set(_vm.newUser, "password", $$v)},expression:"newUser.password"}})]}}],null,true)}),_c('ValidationProvider',{ref:"providerConfirmPassword",attrs:{"rules":"required|confirmed:password","name":"confirmPassword"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"id":"confirmpassword","prepend-icon":"lock","label":"Confirm password","type":"password","error-messages":errors,"data-test":"confirmPassword-text"},model:{value:(_vm.newUser.confirmPassword),callback:function ($$v) {_vm.$set(_vm.newUser, "confirmPassword", $$v)},expression:"newUser.confirmPassword"}})]}}],null,true)})],1),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{attrs:{"type":"submit","color":"primary","data-test":"login-btn"},on:{"click":function($event){return passes(_vm.signUp)}}},[_vm._v(" SIGN UP ")])],1),_c('v-card-subtitle',{staticClass:"d-flex align-center justify-center pa-4 mx-auto"},[_vm._v(" Already have an account? "),_c('router-link',{staticClass:"ml-1",attrs:{"to":{ name: 'login' }}},[_vm._v(" Log in ")])],1)]}}],null,false,84142733)})],1):_vm._e(),_c('AccountCreated',{attrs:{"show":_vm.showMessage,"username":_vm.newUser.username,"data-test":"accountCreated-component"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }